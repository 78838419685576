<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Register Account</h5>
                  <p class="text-muted">
                    Actualmente no se encuentra un usuario administrador
                    registrado en el sistema, por favor registre uno a
                    continuación.
                  </p>
                </div>
                <div class="p-2 mt-4">
                  <div
                    v-if="showMessageError"
                    :class="`alert  alert-${notification.type}`"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToRegisterIn">
                    <b-form-group
                      id="email-group"
                      label="Username"
                      class="mb-3"
                      label-for="userName"
                    >
                      <b-form-input
                        id="userName"
                        v-model="user.userName"
                        type="text"
                        placeholder="Enter userName"
                        :class="{
                          'is-invalid': submitted && $v.user.userName.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.username.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.username.required"
                          >Nombre de usuario es requerido.</span
                        >
                        <span v-if="!$v.user.username.maxLength">
                          16 caracteres maximos</span
                        >
                        <span v-if="!$v.user.username.alphaNum">
                          Solo Valores alfanumericos</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="fullname-group"
                      label="Email"
                      label-for="email"
                      class="mb-3"
                    >
                      <b-form-input
                        id="email"
                        v-model="user.email"
                        type="email"
                        placeholder="Enter email"
                        :class="{
                          'is-invalid': submitted && $v.user.email.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.required"
                          >Email es requerido</span
                        >
                        <span v-if="!$v.user.email.email"
                          >Ingrese un email valido.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="password-group"
                      label="Password"
                      class="mb-3"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="user.password"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid': submitted && $v.user.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.password.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.password.required"
                          >Contraseña es requerida.</span
                        >
                        <span v-if="!$v.user.password.passwordRegex"
                          >Minimo 8 caracteres. Maximo 16. Al menos una letra
                          mayúscula. Al menos una letra minucula. Al menos un
                          dígito. No espacios en blanco. Al menos 1 caracter
                          especial.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      id="password-group"
                      label="Confirm Password"
                      class="mb-3"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="user.confirmPassword"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid':
                            submitted && $v.user.confirmPassword.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.confirmPassword.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.confirmPassword.required"
                          >Contraseña es requerida.</span
                        >
                        <span v-if="!$v.user.confirmPassword.sameAsPassword"
                          >Las contraseñas deben coincidir</span
                        >
                      </div>
                    </b-form-group>
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm"
                        >Register</b-button
                      >
                    </div>

                    <div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="font-size-14 mb-3 title">Sign up using</h5>
                      </div>

                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-primary text-white border-primary"
                          >
                            <i class="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-info text-white border-info"
                          >
                            <i class="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-danger text-white border-danger"
                          >
                            <i class="mdi mdi-google"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-4 text-center">
                      <p class="text-muted mb-0">
                        Already have an account ?
                        <router-link to="/login" class="fw-medium text-primary"
                          >Login</router-link
                        >
                      </p>
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} Minible. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Themesbrand
              </p>
            </div>
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import {
  required,
  email,
  alphaNum,
  helpers,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

const passwordRegex = helpers.regex(
  "password",
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,16}$/
);

/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      user: {
        username: null,
        email: null,
        password: null,
      },
      submitted: false,
      tryingToRegister: false,
      isRegisterError: false,
      title: "Register",
      showMessageError: false,
    };
  },
  validations: {
    user: {
      userName: {
        required,
        maxLength: maxLength(16),
        alphaNum,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        passwordRegex,
      },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
    ...mapGetters({
      userIsRegistered: "authfack/isUserRegistered",
    }),
  },
  mounted() {
    document.body.classList.add("authentication-bg");
    this.$http
      .get("/users")
      .then((res) => {
        let isThereAnUser = res.data.users.length;
        if (isThereAnUser) return this.$router.push("login");
      })
      .catch((err) => {
        throw err;
      });
  },
  methods: {
    // Try to register the user in with the email, userName
    // and password they provided.
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.user.password !== this.user.passwordRepeat) {
        this.showMessageError = true;
        this.$store.dispatch(
          "notification/setErrorRepeatingPassword",
          "Please confirm your password correctly"
        );
      }

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.$store
              .dispatch("auth/register", {
                email: this.user.email,
                password: this.user.password,
              })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                if (this.registerSuccess) {
                  this.$router.push(
                    this.$route.query.redirectFrom || {
                      path: "/",
                    }
                  );
                }
              })
              .catch((error) => {
                this.tryingToRegister = false;
                this.regError = error ? error : "";
                this.isRegisterError = true;
              })
          );
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { email, userName, password } = this.user;
          if (email && userName && password) {
            await this.$store.dispatch("authfack/registeruser", this.user);
            const userStatus =
              (await this.userIsRegistered.registering) || false;

            this.showMessageError = userStatus
              ? this.showMessageError
              : userStatus;

            if (userStatus) {
              Swal.fire({
                title: "Usuario registrado",
                text: "Ya puede acceder al sistema",
                showConfirmButton: false,
                icon: "success",
                timer: 2500,
              });

              setTimeout(() => {
                this.$router.push("login");
              }, 2500);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" module></style>
