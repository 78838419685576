var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"account-pages my-5 pt-sm-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[_vm._m(0),_c('div',{staticClass:"p-2 mt-4"},[(_vm.showMessageError)?_c('div',{class:`alert  alert-${_vm.notification.type}`},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.tryToRegisterIn.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"email-group","label":"Username","label-for":"userName"}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.submitted && _vm.$v.user.userName.$error,
                      },attrs:{"id":"userName","type":"text","placeholder":"Enter userName"},model:{value:(_vm.user.userName),callback:function ($$v) {_vm.$set(_vm.user, "userName", $$v)},expression:"user.userName"}}),(_vm.submitted && _vm.$v.user.username.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.username.required)?_c('span',[_vm._v("Nombre de usuario es requerido.")]):_vm._e(),(!_vm.$v.user.username.maxLength)?_c('span',[_vm._v(" 16 caracteres maximos")]):_vm._e(),(!_vm.$v.user.username.alphaNum)?_c('span',[_vm._v(" Solo Valores alfanumericos")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"fullname-group","label":"Email","label-for":"email"}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                      },attrs:{"id":"email","type":"email","placeholder":"Enter email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email es requerido")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Ingrese un email valido.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"password-group","label":"Password","label-for":"password"}},[_c('b-form-input',{class:{
                        'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                      },attrs:{"id":"password","type":"password","placeholder":"Enter password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),(_vm.submitted && _vm.$v.user.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password.required)?_c('span',[_vm._v("Contraseña es requerida.")]):_vm._e(),(!_vm.$v.user.password.passwordRegex)?_c('span',[_vm._v("Minimo 8 caracteres. Maximo 16. Al menos una letra mayúscula. Al menos una letra minucula. Al menos un dígito. No espacios en blanco. Al menos 1 caracter especial.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"password-group","label":"Confirm Password","label-for":"password"}},[_c('b-form-input',{class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.user.confirmPassword.$error,
                      },attrs:{"id":"password","type":"password","placeholder":"Enter password"},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),(_vm.submitted && _vm.$v.user.confirmPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.confirmPassword.required)?_c('span',[_vm._v("Contraseña es requerida.")]):_vm._e(),(!_vm.$v.user.confirmPassword.sameAsPassword)?_c('span',[_vm._v("Las contraseñas deben coincidir")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mt-3 text-end"},[_c('b-button',{staticClass:"w-sm",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Register")])],1),_c('div',{staticClass:"mt-4 text-center"},[_c('div',{staticClass:"signin-other-title"},[_c('h5',{staticClass:"font-size-14 mb-3 title"},[_vm._v("Sign up using")])]),_c('ul',{staticClass:"list-inline"},[_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"social-list-item bg-primary text-white border-primary",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"mdi mdi-facebook"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"social-list-item bg-info text-white border-info",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"mdi mdi-twitter"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"social-list-item bg-danger text-white border-danger",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"mdi mdi-google"})])])])]),_c('div',{staticClass:"mt-4 text-center"},[_c('p',{staticClass:"text-muted mb-0"},[_vm._v(" Already have an account ? "),_c('router-link',{staticClass:"fw-medium text-primary",attrs:{"to":"/login"}},[_vm._v("Login")])],1)])],1)],1)])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Minible. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Themesbrand ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-2"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Register Account")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Actualmente no se encuentra un usuario administrador registrado en el sistema, por favor registre uno a continuación. ")])])
}]

export { render, staticRenderFns }